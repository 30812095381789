<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card :heading="page.title" :dialog="true">
      <template v-slot:customButton>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-on="on" v-bind="attrs" dark @click="getCoursesNotImported(true)" class="mr-1">
              <v-icon small>mdi-reload</v-icon>&nbsp;&nbsp;Atualizar
            </v-btn>
          </template>
          <span>Atualizar turmas com o Google Sala de Aula</span>
        </v-tooltip>
      </template>
      <datatable-list
        :text="text"
        :headers="headers"
        :sortBy="sortBy"
        v-if="items.length && !sending"
        :displayActionItems="false"
        :items="items"
        @select-item="importCourse($event)"
        :emit="true"
      ></datatable-list>
      <v-skeleton-loader
        v-for="i in 5"
        v-else-if="!items.length && sending"
        :key="i"
        type="table-heading"
      ></v-skeleton-loader>
      <p class="text-center pt-5 pb-5" v-else>
        Nenhuma turma encontrada
      </p>
    </base-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  data: () => ({
    page: {
      title: 'Importar turmas do Google Sala de Aula'
    },
    dialog: {
      show: false,
      title: 'Importar turmas'
    },
    id: '',
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Escolas',
        disabled: false,
        to: '/school'
      }
    ],
    activeCourse: { name: '' },
    text: {
      searchLabel: 'Pesquisar turmas',
      emptyLabel: 'Nenhuma turma encontrada',
      importLabel: 'Importar Escolas'
    },
    headers: [
      {
        text: 'Nome',
        value: 'name',
        align: 'start'
      },
      { text: 'Turno', value: 'section', align: 'center' },
      { text: 'Sala', value: 'room', align: 'center' }

    ],
    sortBy: '',
    showSelect: false,
    items: [],
    school: {},
    sending: false
  }),
  watch: {
    'pagination.page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getPeople()
      }
    }
  },
  methods: {
    ...mapMutations(['setLoading', 'setSelectedCourse']),
    openDialog () {
      this.dialog.show = true
    },
    getCoursesNotImported (refresh_cache = false) {
      this.sending = true
      this.items = []
      this.$axios.get('/auth/my-classroom-courses', {
        params: {
          refresh_cache
        }
      })
        .then(res => {
          this.items = res.data.data.filter(item => !item.pruvo.is_imported).map(item => {
            return {
              id: item.id,
              name: item.name || 'Não definido',
              section: item.section || 'Não definido',
              room: item.room || 'Não definido'
            }
          })
        })
        .finally(() => (this.sending = false))
    },
    importCourse (item) {
      this.setLoading(true)
      this.setSelectedCourse(item)
      this.$router.push({ path: `/course/add/${this.$route.params.id}` })
    },
    async getSchoolData (organizationId) {
      this.$axios.get(`organizations/${organizationId}/schools/${this.$route.params.id}`)
        .then(response => {
          this.school = response.data.data
        })
        .catch(() => {
          const msg = 'Erro na conexão, tente novamente'
          this.$store.dispatch('alert', { color: 'red', msg })
        })
        .finally(() => {
          this.schoolFetch = true
          this.mountBreadcrumb()
        })
    },
    mountBreadcrumb () {
      this.breadcrumbs.push(
        {
          text: this.school.name,
          disabled: false,
          to: `/school/${this.$route.params.id}`
        },
        {
          text: 'Turmas',
          disabled: false,
          to: `/school/course/${this.$route.params.id}`
        },
        {
          text: this.page.title,
          disabled: false,
          to: `/course/import/${this.$route.params.id}/`
        }
      )
    }
  },
  async mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')

    await this.getSchoolData(this.id)

    this.getCoursesNotImported()
  }
}
</script>
